<template>
	<div class="index-second-section" ref="thisSect">
		<div class="sect-holder" ref="sectHolder">
			<div class="main-text-wrap" ref="mainTextElem">
				<span class="main-text">We doing incredible things more than 17 years</span>
			</div>
			<div class="sect-sequence">
				<loader-sequence
					path="assets/images/static/sequence-index1"
					:start-frame="startFrame"
					:end-frame="endFrame"
					extension="jpg"
					@canvas-images="testFunc"
				/>
				<canvas class="sequence" ref="canvasElem"></canvas>
			</div>
			<div class="reels-box">
				<div class="reels-holder">
					<span class="reels-title" ref="reelsTitleElem">view our <br />showreel</span>
					<div class="reels-frame" ref="reelsFrameElem">
						<div class="reels" ref="reelsElem">
							<img src="@/assets/images/second-sect-video-thumb.jpg" alt="" />
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-textbox">
				<div class="container">
					<div class="bottom-row" ref="bottomTextElem">
						<span class="text1">We innovate <br />and creativity</span>
						<span class="text2">into your <br />project</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import './index-second-section.scss'
import { ref, nextTick, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import LoaderSequence from '@/components/Loader/loader-sequence.vue'
gsap.registerPlugin(ScrollTrigger)

let animationTimeLine = gsap.timeline({
	paused: true,
	repeatDelay: 2,
	repeat: 3
})
let mainAnimationTL = gsap.timeline({
	paused: true
})

const typeText1 = ref()
const centerContentBox = ref()
const scrollTimeLine = ref()
const thisSect = ref()
const sectHolder = ref()
const mainTextElem = ref()
const reelsFrameElem = ref()
const reelsElem = ref()
const reelsTitleElem = ref()
const bottomTextElem = ref()

const canvasElem = ref()
const startFrame = 0
const endFrame = 79

let canvas
let context
let sequenceTimeLineStart
let sequenceAnimation = gsap.timeline({
	paused: true
})
let canvasImages = []
const frames = {
	frame: startFrame
}

const isAllLoaded = computed(() => {
	return store.getters['preloader/isAllLoaded']
})
nextTick(() => {
	console.log(`next tick mounted index top screen`)
})
watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		console.log(`all loaded from sect`)

		animations()
	},
	{ immediate: false }
)
onMounted(() => {
	console.log(`mounted index second section`)
	canvas = canvasElem.value
	canvas.width = 1920
	canvas.height = 1080
	context = canvas.getContext('2d')
})

function runSequence() {
	sequenceTimeLineStart = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: () => window.innerHeight / 2,
			end: () => 'bottom top',
			toggleActions: 'play none none reverse',
			scrub: false,
			invalidateOnRefresh: true,
			onToggle: (self) => {
				console.log(self)
				if (self.isActive === true) {
					sequenceAnimation.seek(0)
					sequenceAnimation.play()
				} else {
					console.log(`ended`)
				}
			}
		}
	})
	sequenceAnimation.fromTo(
		frames,
		{
			frame: startFrame
		},
		{
			duration: 2,
			frame: () => endFrame,
			roundProps: 'frame',
			ease: Linear.easeNone,
			onUpdate: function () {
				drawFrameToCanvas()
			},
			onComplete: function () {
				console.log(`complete`)
			}
		}
	)
}

function animations() {
	// фиксим секцию
	const TL = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 0,
			end: 'bottom top',
			pin: sectHolder.value,
			invalidateOnRefresh: true
		}
	})

	//большой текст, снизу вверх скролл
	const TL1 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 0,
			end: '+=' + window.innerHeight * 2,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TL1.fromTo(mainTextElem.value, { yPercent: 50 }, { yPercent: -5, ease: 'none' }, 0)

	//Фрейм рилса
	const TL2 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: window.innerHeight,
			end: '+=' + window.innerHeight,
			toggleActions: 'play none none reverse',
			//scrub: true,
			invalidateOnRefresh: true
		}
	})
	TL2.fromTo(
		reelsFrameElem.value,
		{ height: '0%', width: '0%', borderRadius: 0 },
		{ height: '100%', width: '100%', borderRadius: 170, duration: 1, ease: Expo.easeInOut },
		0
	)
	TL2.fromTo(
		reelsTitleElem.value,
		{ x: -50, opacity: 0 },
		{ x: 0, opacity: 1, duration: 2, ease: Expo.easeOut },
		0.5
	)
	//большой текст, затемнение
	const TL3 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: window.innerHeight / 1.5,
			end: '+=' + window.innerHeight,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TL3.fromTo(mainTextElem.value, { opacity: 1 }, { opacity: 0.2, ease: 'none' }, 0)
	TL3.fromTo(bottomTextElem.value, { yPercent: 100 }, { yPercent: 0, ease: 'none' }, 0)
}
function testFunc(sortedLoadedCanvasImages) {
	canvasImages = sortedLoadedCanvasImages
	runSequence()
}

function drawFrameToCanvas() {
	context.clearRect(0, 0, canvas.width, canvas.height)
	context.drawImage(
		canvasImages.find((img) => img.uid === frames.frame),
		0,
		0
	)
}
</script>
