<script setup>
import { computed, inject, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import './FrontPage.scss'
//my components
import loaderImg from '@/components/Loader/loader-img.vue'
import LoaderSequence from '@/components/Loader/loader-sequence.vue'
import ImgLoadScroll from '@/components/ImageLoadOnScrol/img-load-scroll.vue'
import SkarallaxBlock from '@/components/skarallax/skarallax-block.vue'
//sections
import IndexFirstSection from '@/views/FrontPage/index-first-section.vue'
import IndexSecondSection from '@/views/FrontPage/index-second-section.vue'

//variables
const emit = defineEmits(['mount'])
const store = useStore()

const isMobile = inject('isMobileCheck')
const isRetina = inject('isRetina')

function handleTest() {
	isHighDensity()
}
function handleLoadAllPictures() {
	store.dispatch('preloader/actReadyToLoad', true)
}
const loaderAllPicsAmount = computed(() => {
	return store.getters['preloader/allPicsAmount']
})
const picsToLoad = computed(() => {
	return store.getters['preloader/picsToLoad']
})

const isRetinaComp = computed(() => {
	return isRetina
})
onMounted(() => {
	console.log(`mounted frontpage`)
	store.dispatch('preloader/actReadyToLoad', true)
})
onUnmounted(() => {
	console.log(`unmount frontpage`)
})
function isHighDensity() {
	console.log(isRetina.value)
}
</script>
<template>
	<index-first-section />
	<index-second-section />
	<div class="seq-box">
		<!--		<loader-sequence-->
		<!--			path="assets/images/static/sequence1"-->
		<!--			:end-frame="250"-->
		<!--			:debug="true"-->
		<!--			:on-scroll="false"-->
		<!--		/>-->
	</div>

	<div class="top-screen-box contracts-page">
		<strong>
			Осталось <b style="color: red">{{ picsToLoad }}</b> из
			<i style="font-style: normal; color: green">{{ loaderAllPicsAmount }}</i> картинок
		</strong>
		<h2 @click="handleTest()">Что у нас в в очереди {{ isRetinaComp }}</h2>
		<button @click="handleLoadAllPictures">Загрузить все в очереди</button>

		<div
			class="spacer"
			style="
				height: 1000px;
				background: #666;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				border: 5px dashed #fdd094;
			"
		>
			<span>spacer</span>
		</div>
		<skarallax-block :y="400" style="position: relative; z-index: 5">
			<div class="spacer" style="background: yellow; width: 100%; height: 500px"></div>
		</skarallax-block>
		<div class="scale-block" style="overflow: hidden">
			<skarallax-block
				:y="0"
				:scale-from="1"
				:scale-to="1.3"
				style="position: relative; z-index: 6"
			>
				<loader-img src="assets/images/static/girl.png" :width="1295" :height="913" />
			</skarallax-block>
		</div>

		<img-load-scroll src="assets/images/static/girl.png" :thumb="true" :loader="true" />
		<div
			class="spacer"
			style="
				height: 1000px;
				background: #666;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				border: 5px dashed #fdd094;
			"
		>
			<span>spacer</span>
		</div>
		<loader-img src="assets/images/static/1.jpg" />
		<loader-img src="assets/images/static/2.jpg" />
	</div>
</template>
