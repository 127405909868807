<script setup>
import './index-first-section.scss'
import LoaderVideo from '@/components/Loader/loader-video.vue'

import { ref, nextTick, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

import { gsap, Power2, Expo, Power1, Power3 } from 'gsap'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger)

let animationTimeLine = gsap.timeline({
	paused: true,
	repeatDelay: 2,
	repeat: 3
})
let mainAnimationTL = gsap.timeline({
	paused: true
})

const typeText1 = ref()
const centerContentBox = ref()
const scrollTimeLine = ref()
const thisSect = ref()
const sectHolder = ref()
let videoElem = null
const isAllLoaded = computed(() => {
	return store.getters['preloader/isAllLoaded']
})
nextTick(() => {
	console.log(`next tick mounted index top screen`)
	let previouslyCreatedSmoother = ScrollSmoother.get()
	console.log(previouslyCreatedSmoother)
})

watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		videoElem = thisSect.value.getElementsByTagName('video')[0]
		videoElem.play()
		videoElem.addEventListener('ended', onVideoEnd, false)
		mainAnimationTL.play()
	},
	{ immediate: false }
)
onMounted(() => {
	console.log(`mounted index top screen`)
	starterAnimations()
	scrollingAnimations()
})
function onVideoEnd() {
	console.log(`ended`)
	videoElem.currentTime = 1.5
	videoElem.play()
}
function starterAnimations() {
	console.log(`'starters`)
	mainAnimationTL.from(
		centerContentBox.value,
		{
			scale: 10,
			filter: 'blur(5px)',
			duration: 1,
			delay: 0.5,
			ease: Power3.easeOut
		},
		0
	)
	mainAnimationTL.from(
		centerContentBox.value,
		{
			opacity: 0,
			duration: 0.1,
			delay: 0.5,
			ease: 'none'
		},
		0
	)

	// animationTimeLine.to(
	// 	typeText1.value,
	// 	{
	// 		text: {
	// 			value: 'design',
	// 			delimiter: ''
	// 		},
	//
	// 		duration: 0,
	// 		onReverseComplete: () => {
	// 			typeText1.value.classList.add('motion')
	// 		},
	// 		ease: Expo.easeOut
	// 	},
	// 	0
	// )
	// animationTimeLine.to(
	// 	typeText1.value,
	// 	{
	// 		text: {
	// 			value: 'design',
	// 			delimiter: ''
	// 		},
	// 		reversed: true,
	// 		duration: 1,
	// 		onReverseComplete: () => {
	// 			typeText1.value.classList.add('motion')
	// 		},
	// 		ease: Expo.easeOut
	// 	},
	// 	0
	// )
	// animationTimeLine.to(
	// 	typeText1.value,
	// 	{
	// 		text: {
	// 			value: 'motion'
	// 		},
	// 		duration: 0.5,
	// 		ease: Expo.easeOut
	// 	},
	// 	1.2
	// )
	// animationTimeLine.to(
	// 	typeText1.value,
	// 	{
	// 		text: {
	// 			value: 'motion',
	// 			delimiter: ''
	// 		},
	// 		reversed: true,
	// 		duration: 1,
	// 		onReverseComplete: () => {
	// 			typeText1.value.classList.add('thred')
	// 		},
	// 		ease: Expo.easeOut
	// 	},
	// 	4.5
	// )
	// animationTimeLine.to(
	// 	typeText1.value,
	// 	{
	// 		text: {
	// 			value: 'effects'
	// 		},
	// 		duration: 0.5,
	// 		ease: Expo.easeIn
	// 	},
	// 	5.5
	// )
	// animationTimeLine.to(
	// 	typeText1.value,
	// 	{
	// 		text: {
	// 			value: 'effects',
	// 			delimiter: ''
	// 		},
	// 		reversed: true,
	// 		duration: 1,
	// 		onReverseComplete: () => {
	// 			typeText1.value.classList.remove('thred', 'motion')
	// 		},
	// 		ease: Expo.easeOut
	// 	},
	// 	8
	// )
	// animationTimeLine.to(
	// 	typeText1.value,
	// 	{
	// 		text: {
	// 			value: 'design',
	// 			delimiter: ''
	// 		},
	// 		duration: 0.5,
	// 		ease: Expo.easeIn
	// 	},
	// 	9
	// )
}
function scrollingAnimations() {
	const TL = gsap.timeline({
		scrollTrigger: {
			start: 0,
			end: '+=' + window.innerHeight,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TL.fromTo(sectHolder.value, { height: '100%', width: '100%' }, { height: '50%', width: '70%' }, 0)
	TL.fromTo(centerContentBox.value, { yPercent: 0 }, { yPercent: 50, ease: 'none' }, 0)
}
</script>

<template>
	<div class="index-top-screen-section" ref="thisSect">
		<div class="sect-holder" ref="sectHolder">
			<div class="sect-frame">
				<div class="sect-content">
					<div class="main-content-box">
						<div class="container">
							<div class="center-content-box" ref="centerContentBox">
								<h1>
									<b>we love</b>
									<span>
										<b ref="typeText1">design</b>
										<i>.</i>
									</span>
								</h1>
							</div>
						</div>
					</div>
					<div class="bottom-content-box">
						<div class="container">
							<div class="bottom-row">
								<span class="insp-text">Our inspiration <br />design love</span>
								<span class="since">Since 2001</span>
							</div>
						</div>
					</div>
				</div>
				<div class="sect-bg">
					<loader-video src="assets/video/index-top-video2.mp4" :autoplay="false" :loop="false" />
				</div>
			</div>
		</div>
	</div>
</template>
