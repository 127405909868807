<script setup>
//Тег который в зависимости от условий, подставляет url ретина
import { onMounted, computed, ref, watch, inject, nextTick } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const emit = defineEmits(['canvasImages'])

import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
	path: {
		type: String,
		required: true,
		default: ''
	},
	startFrame: {
		type: Number,
		required: false,
		default: 0
	},
	endFrame: {
		type: Number,
		required: true,
		default: 0
	},
	extension: {
		type: String,
		required: false,
		default: 'webp'
	},
	debug: {
		type: Boolean,
		required: false
	}
})
const isLoaded = ref(false)
const imagesUrls = ref([])
let sortedCanvasImages = []

function prepareArrayToLoad(path) {
	for (let i = props.startFrame; i <= props.endFrame; i++) {
		const imagePath = '/' + props.path + '/' + i.toString().padStart(4, '0') + '.' + props.extension
		imagesUrls.value.push(new URL(imagePath, import.meta.url).href)
		store.commit('preloader/plus')
	}
}

const loadImage = (url, uid) =>
	new Promise((resolve, reject) => {
		const img = new Image()
		img.addEventListener('load', () => resolve(img))
		img.addEventListener('error', (err) => reject(err))
		img.uid = uid
		img.src = url
	})

const canvasImages = computed(() => {
	let imgArray = []
	console.log(store.state.preloader.isReadyToLoad)
	if (store.state.preloader.isReadyToLoad === true) {
		imagesUrls.value.map((imgPath, index) => {
			loadImage(imgPath, index)
				.then((img) => {
					if (props.debug === true)
						console.log(`${imgPath} ${'%c'} is loaded`, 'background: #222; color: #bada55')
					imgArray.push(img)
					store.commit('preloader/minus')
				})
				.catch((err) => {
					console.log(`${imgPath} ${'%c'}is NOT loaded`, 'background: #222;; color: red')
				})
		})
	}

	console.log(imgArray)

	return imgArray
})

watch(
	() => canvasImages.value,
	(canvasImages) => {},
	{ immediate: false }
)

watch(
	() => store.state.preloader.allResourcesLoaded,
	(loaded) => {
		if (loaded === true) {
			emit('canvasImages', canvasImages.value)
		}
	},
	{ immediate: false }
)

function handleLoadedImage() {
	isLoaded.value = true
}
function handleErrorLoadingImage() {
	console.error(`image ${imageElement.value.src} is not loaded`)
}
onMounted(() => {
	prepareArrayToLoad()
})
</script>

<template></template>
