<script setup>
import { onMounted, onUnmounted, computed, ref, inject, watch } from 'vue'
import { gsap, Expo } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import './main-menu.scss'
const isRetina = inject('isRetina')
const isLoaded = ref(false)
const menuElem = ref()

const isMenuOpened = ref(false)
const menuTriggerSequence = gsap.timeline({
	scrollTrigger: {
		start: () => window.innerHeight,
		toggleActions: 'play none none reverse',
		scrub: false,
		invalidateOnRefresh: true,
		onToggle: (self) => {
			console.log(self.progress)
			if (self.progress > 0) {
				menuSequence.play()
			} else {
				isMenuOpened.value = false
				menuSequence.reverse()
			}
		}
	}
})
let menuSequence = gsap.timeline({ paused: true })
onMounted(() => {
	menuSequence.fromTo(menuElem.value, { yPercent: 100 }, { yPercent: 0, ease: Expo.easeOut }, 0)
})
onUnmounted(() => {
	menuTriggerSequence.kill()
	menuSequence.kill()
})
watch(
	() => isMenuOpened.value,
	(isOpened) => {
		if (isOpened) {
			window.addEventListener('scroll', function () {
				isMenuOpened.value = false
			})
		}
	},
	{ immediate: false }
)
function handleMenuBtnClick() {
	isMenuOpened.value = !isMenuOpened.value
	console.log(isMenuOpened.value)
}
</script>

<template>
	<div class="menu-box" :class="{ opened: isMenuOpened }" ref="menuElem">
		<button class="close-btn" type="button" @click="isMenuOpened = false"></button>
		<div class="menu-ovh-wrap">
			<div class="drop">
				<ul class="main-nav">
					<li class="active"><a href="#">home</a></li>
					<li><a href="#">about us</a></li>
					<li><a href="#">works</a></li>
				</ul>
			</div>
			<button class="menu-btn" type="button" @click="handleMenuBtnClick">
				<span>
					<i><b>menu</b><em>close</em></i>
				</span>
			</button>
		</div>
	</div>
</template>
