<script setup>
import { inject, computed, reactive, ref, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { switchLanguage } from '@/i18n'
import './Header.scss'
const emitter = inject('emitter')
const store = useStore()

import { gsap, Power2, Expo, Power1 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
gsap.registerPlugin(ScrollTrigger)

const isShowedLangMenu = ref(false)
const currentLang = ref('ru')
const langList = ref(['ru', 'en'])
const otherLang = ref('en')
const logoElem = ref()
onMounted(() => {
	console.log(`mounted index second section`)
})
nextTick(() => {
	animations()
})
function changeLang() {
	const code = otherLang.value
	otherLang.value = currentLang.value
	currentLang.value = code
	switchLanguage(code)
}
function animations() {
	const TL2 = gsap.timeline({
		scrollTrigger: {
			start: 0,
			scrub: true,
			end: '+=' + window.innerHeight,
			invalidateOnRefresh: true
		}
	})
	TL2.fromTo(logoElem.value, { y: 0 }, { y: -200, ease: 'none' })
}
function handleWorkLinkClick(element) {
	console.log(element)
	store.commit('preloader/loadPath', element.href)
	store.commit('preloader/goNextPage', true)
}
</script>
<template>
	<header class="header">
		<div class="container">
			<div class="header-row">
				<div class="left-col" ref="logoElem">
					<router-link :to="{ name: 'FrontPage' }" class="logo">
						<img src="/assets/images/vector/logo.svg" alt="" />
					</router-link>
				</div>
				<div class="right-col">
					<ul class="works-nav">
						<li>
							<a href="/works/axolot" @click.prevent="handleWorkLinkClick"> <span>Axolot</span></a>
						</li>
						<li>
							<a href="/works/flussonic" @click.prevent="handleWorkLinkClick"> <span>Flussonic</span></a>
						</li>
						<li>
							<a href="/works/interswap" @click="handleWorkLinkClick"> <span>Interswap</span></a>
						</li>
						<li>
							<a href="/works/racino" @click="handleWorkLinkClick"> <span>Racino</span></a>
						</li>
						<li>
							<a href="/works/flow" @click="handleWorkLinkClick"> <span>Flow</span></a>
						</li>
					</ul>

					<div class="soc-links">
						<a href="#"><img src="/assets/images/vector/soc-telegram.svg" alt="" /></a>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>
