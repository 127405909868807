export default {
	namespaced: true,
	state: {
		isFullVideoModalOpen: false,
		fullVideoModalSrc: ''
	},

	actions: {},

	mutations: {
		fullModalOpened(state, value) {
			state.isFullVideoModalOpen = value
		},
		fullModalSrc(state, value) {
			state.fullVideoModalSrc = value
		}
	},

	getters: {
		fullVideoOpenModal(state) {
			return state.isFullVideoModalOpen
		},
		fullModalSrc(state) {
			return state.fullVideoModalSrc
		}
	}
}
