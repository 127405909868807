<script setup>
import './Preloader.scss'
import { computed, inject, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

import { Expo, gsap, Power1 } from 'gsap'
import { useRouter } from 'vue-router'
const router = useRouter()
let animationTimeLine = gsap.timeline({
	paused: true
})
let loaderNumbersTL = gsap.timeline({
	paused: true
})
let animationTimeLineIn = gsap.timeline({
	paused: false
})
const loaderAllPicsAmount = computed(() => {
	return store.getters['preloader/allPicsAmount']
})
const picsToLoad = computed(() => {
	return store.getters['preloader/picsToLoad']
})
const isGoNextPage = computed(() => store.getters['preloader/goNextPage'])
const loadPath = computed(() => store.getters['preloader/loadPath'])
const percentsLoaded = computed(() => {
	let percent = store.getters['preloader/percentLoaded']
	//console.log(percent)
	return store.getters['preloader/percentLoaded']
})
const isAllLoaded = computed(() => {
	return store.getters['preloader/isAllLoaded']
})
const preloaderElem = ref()
const numbersElem = ref()
onMounted(() => {
	console.log(`mounted preloader`)
	loaderNumbersTL.fromTo(
		numbersElem.value,
		{
			scale: 1
		},
		{
			scale: 0.05,
			duration: 1,
			ease: Power1.easeIn
		},
		0
	)
	animationTimeLine.fromTo(
		preloaderElem.value,
		{
			autoAlpha: 1
		},
		{
			autoAlpha: 0,
			duration: 1,
			ease: Power1.easeIn
		},
		0
	)
})
watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		if (allLoaded) launch()
	},
	{ immediate: false }
)
watch(
	() => isGoNextPage.value,
	(isGoNextPage) => {
		if (isGoNextPage) {
			console.log(`show Preloader`)
			animationTimeLineIn.fromTo(
				preloaderElem.value,
				{
					autoAlpha: 0
				},
				{
					autoAlpha: 1,
					duration: 0.5,
					ease: Power1.easeIn
				},
				0
			)
			//router.push(loadPath.value)
		}
	},
	{ immediate: false }
)
function launch() {
	animationTimeLine.play()
	loaderNumbersTL.play()
	setTimeout(function () {
		loaderNumbersTL.revert()
	}, 1000)
}
</script>

<template>
	<div class="page-preloader" ref="preloaderElem">
		<div class="holder">
			<div class="numbers-box" ref="numbersElem">
				<span>loading {{ isAllLoaded }}</span>
				<b class="progress-numbers">{{ ('0' + percentsLoaded).slice(-2) }}</b>
				<strong>
					Loading <em style="color: red">{{ picsToLoad }}</em> from
					<i style="font-style: normal; color: green">{{ loaderAllPicsAmount }}</i>
				</strong>
			</div>
		</div>
	</div>
</template>
